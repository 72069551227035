<template>
    <footer id="footer" class="container-fluid footer bg-black p-4 p-md-5">
        <nav @click="handleLogo" class="d-flex justify-content-center align-items-center">
            <router-link :to="{ path: $route.path }">
                <img src="@/assets/icons/by-tradeupgroup.svg" width="100" alt="Logotipo escrito by TradeUp group">
            </router-link>

            <a :href="whatsappLink" class="position-fixed whatsapp-rounded-button" target="_blank">
                <Icon name="icon-whats-app" class="footer-icon img-auto mt-0 icon-whats-app-icon" />
            </a>
        </nav>
    </footer>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        name: "Footer",
        computed: {
            ...mapGetters({
                getPhoneNumber: "getPhoneNumber",
                getShowClaroFieldsComputed: "address/getShowClaroFields",
            }),
            getPhoneNumberComputed() {
                return this.getPhoneNumber;
            },
            whatsappLink() {
                return "https://wa.me/" + this.getPhoneNumber;
            },

        },
        methods: {
            handleLogo() {
                if (this.getShowClaroFieldsComputed) {
                    return this.$router.push("/clarotv");
                }

                return this.$router.push("/");
            },
        },
    };
</script>

<style>
    .whatsapp-rounded-button {
        bottom: 2rem;
        right: 2rem;
    }
</style>
